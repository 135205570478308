<template>
<div>
  <WidgetLayout :payload="data"/>
  <div id="form" class="customForm container">
    <div class="formTitle" v-if="form" v-html="form.title" />
    <form id="foss-600ff4f4d59cbc327881f7ec"></form>
  </div>
  </div>
</template>

<script>
import WidgetLayout from "@/components/WidgetLayout.vue"
import json from '../../../public/json/landing2.json'

export default {
  components: {
    WidgetLayout
  },
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://api.plezi.co/api/v1/web_forms/scripts?tenant_id=5b645a8754067a4f27cb91d8&form_id=5e749529d59cbc3c7d1d0e8f&form_version=3&content_web_form_id=600ff4f4d59cbc327881f7ec&country=France&plz_lang=fr')
    document.head.appendChild(externalScript)
  },
  metaInfo: {
    meta: [{
      name: '',
      content: ''
    }]
  },
  computed: {
    form() {
      return this.data.find(f => f.type === "form")
    },
    data() {
      return json.landing
    }
  }
}
</script>